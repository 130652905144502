import './App.css';
import React from 'react';
import Keyboard from './Keyboard'
import Guess from './Letters'
import { getWord, checkWord } from './services/Words';

export class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      turn: 0,
      targetWord: "",
      guessLetters: Array(5).fill(Array(5).fill(null)),
      guessTypes: Array(5).fill(Array(5).fill("guess")),
      currentLetter: 0,
      correct: [],
      order: [],
      wrong: [],
      gameOver: false,
      showWord: false,
      showIncorrect: false
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
  }

  submitGuess() {
    const turn = this.state.turn;
    const letters = this.state.guessLetters[turn];
    let exists;
    let guess;
    let newWrong;
    const response = Promise.resolve(checkWord(letters))
    response.then(result => {
      exists = result.exists;
      if (!exists)
      {
        this.setState({showIncorrect: true});
        return;
      }
      guess = result.guess;
      newWrong = result.wrong;

      let guessTypes = [...this.state.guessTypes];
      let newTypes = []
      let correct = this.state.correct;
      let order = this.state.order;
      let wrong = this.state.wrong;
      for(let i = 0; i < 5; i++) {
        newTypes.push(guess[i]);
        if (guess[i] === 'correct' && !correct.includes(letters[i])) {correct.push(letters[i])}
        else if (guess[i] === 'order' && !order.includes(letters[i])) {order.push(letters[i])};
      }
      newWrong.forEach(element => {
        if (!wrong.includes(element)) {wrong.push(element.toUpperCase())};
      });
      guessTypes[turn] = newTypes;
      let gameOver = false;
      let showWord = false;
      if (correct.length === 5)
      {
        gameOver = true;
      }
      else if (turn > 3) {
        getWord().then(result => this.setState({
          targetWord: result.word.toUpperCase()
        }));
        gameOver = true;
        showWord = true;
      }
      this.setState({
        turn: turn + 1,
        guessTypes: guessTypes,
        correct: correct,
        order: order,
        wrong: wrong,
        currentLetter: 0,
        gameOver: gameOver,
        showWord: showWord,
        showIncorrect: false
      });
    
      return;
  });
  }

  handleClick = (letter) => {
    let key = letter;
    let code = 65;
    if (letter === "<--"){
      key = "Backspace"
      code = 0
    }
    this.fillInput(key, code);
  }


  handleKeyDown = (e) => {
    this.fillInput(e.key, e.keyCode);
  }
  
  fillInput(key, code) {
    const turn = this.state.turn;
    if (this.state.gameOver){
      return;
    }
    let guessLetters = [...this.state.guessLetters];
    let letters = [...guessLetters[turn]];
    let nextLetter;

    if(key === "Backspace" && this.state.currentLetter > 0)
    {
      letters[this.state.currentLetter - 1] = null;
      nextLetter = this.state.currentLetter - 1;
    }
    else if (key === "Enter" && this.state.currentLetter > 4)
    {
      this.submitGuess();
      return;
      
    }
    else if (this.state.currentLetter > 4)
    {
      return;
      
    }
    else if ((code > 64 && code < 91) ||
      ["Ě","Š","Č","Ř","Ž","Ý","Á","Í","É","Ú","Ů","Ň", "Ó"].includes(key.toUpperCase()))
    {
      letters[this.state.currentLetter] = key.toUpperCase();
      nextLetter = this.state.currentLetter + 1;
    }
    else {
      return;
    };
    guessLetters[turn] = letters;
    this.setState({
      guessLetters: guessLetters,
      currentLetter: nextLetter
    });
  }

  help() {
    console.log("Help!")
    let help = document.getElementById("helpPopup")
    if(help.classList.contains("hide-help"))
    {
      help.classList.remove("hide-help")
    }
    else
    {
      help.classList.add("hide-help")
    }
    return;
  }

  render() {
    const guessRows = this.state.guessLetters.map(( (guess, i) => {
      const types = this.state.guessTypes[i];
      return(
        <div className='row' key={i}>
          <Guess 
            letters = {guess}
            types = {types}
            row = {i}
            />
        </div>
      );
    }));
    let showWord = <div/>;
    if (this.state.showWord){
      showWord = (<div className='showWord'>
        {"Správné slovo bylo: " + this.state.targetWord}
        </div>);
    }
    if (this.state.showIncorrect){
      showWord = (<div className='showWord'>
        {"Zadané slovo neexistuje."}
        </div>);
    }
    return (
      <div className="App">
        <header>

        </header>
        <nav class="navbar">
          <div class="navlist">
            <div class="side_column">
            </div>
            <div class="middle_column">
              <a class="logo" href="/" id="header_logo">TP WORDLE</a>
            </div>
            <div class="side_column">
              <button class="help" onClick={this.help}>?</button>
            </div>
          </div>
        </nav>
        <div class="helpContainer" id="helpPopup" onClick={this.help}>
          <div class="helpPopup">
            <h2>Jak hrát Wordle?</h2>
            <p>Wordle je textová hra při které je cílem uhádnout konkrétní 5-pismenné slovo.</p>
            <h3>Slovník</h3>
            <p>V této verzi hry se hádají pouze <b>podstatná jména, přídavná jména</b> v 1. pádu jednotného čísla a <b>slovesa</b> v infinitivu.</p>
            <h3>Jak hádat?</h3>
            <p>
              Pomocí vaší klávesníce nebo klávesnice na obrazovce zadaváte jednotlivá písmena slova, které chtete hádat.
              Stistkem klávesy <b>Enter</b> odešlete slovo na validaci.<br/><br/>
              Podle podobnosti vašeho hádaného slova k cílovému slovu se u každého písmene objeví jedna z 5 barev:
            </p>
            <div class="letter" id="correct"></div>
            <p class="legend"> - Písmeno je správně a na správném místě</p>
            <div class="letter" id="order"></div>
            <p class="legend"> - Písmeno je správně ale na <b>špatném místě</b></p>
            <div class="letter" id="accent"></div>
            <p class="legend"> - Písmeno je na správném místě, ale se <b>špatnou diakritikou</b></p>
            <div class="letter" id="accentOrder"></div>
            <p class="legend"> - Písmeno je na <b>špatném místě</b> a se <b>špatnou diakritikou</b></p>
            <div class="letter" id="wrong"></div>
            <p class="legend"> - Písmeno je <b>špatně</b></p>
            <h3>Příklad</h3>
            <p>Hádané slovo je DÉLKA a cílove slovo je DĚLAT:</p>
            <div class="row">
              <div class="letter" id="correct">D</div>
              <div class="letter" id="accent">É</div>
              <div class="letter" id="correct">L</div>
              <div class="letter" id="wrong">K</div>
              <div class="letter" id="order">A</div>
            </div>
          </div>
        </div>
        <div className="game">
          <div className="lettersArea">
            {guessRows}
            {showWord}
          </div>
          <div className='keyboardArea'>
            <Keyboard
              correct = {this.state.correct}
              order = {this.state.order}
              wrong = {this.state.wrong}
              onClick = {(letter) => this.handleClick(letter)}
            />
          </div> 
        </div>
      </div>
    );
    }
} 


export default App;

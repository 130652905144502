import React from 'react';
import './Letters.css'

function Square(props) {
    return (
      <div
        className='letter'
        id={props.type}
        >
          {props.letter}
        </div>
    )
  }
  
  export class Guess extends React.Component {
    renderSquare(letter, type, i) {
      return(
        <Square 
          letter={letter} 
          type={type} 
          key={i}
          />
      );
    }
  
    render() {
      const squares = this.props.letters.map((letter, i) => {
        const type = this.props.types[i]
        let squareKey = this.props.row * 10 + i;
        return (this.renderSquare(letter, type, squareKey))
      });
      return (
          <div>
            {squares}
          </div>
        );
      }
      
  }

  export default Guess;
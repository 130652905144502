import React from 'react';
import './Keyboard.css'

function KeyboardButton(props) {
    return (
        <button 
          className='keyboardButton' 
          id={props.type}
          onClick = {() => props.onClick()}>
            {props.letter}
          
        </button>
    )
  }
  
  export class Keyboard extends React.Component {
    rows = [
      ["Ú","Ů","Ň","Ť", "Ď"],
      ["Ě","Š","Č","Ř","Ž","Ý","Á","Í","É",],
      ["Q","W","E","R","T","Y","U","I","O","P"],
      ["A","S","D","F","G","H","J","K","L"],
      ["Enter","Z","X","C","V","B","N","M", "<--"]
    ];
  
    renderButton(letter, type) {
      return(
        <KeyboardButton
          letter = {letter}
          type = {type}
          key = {letter}
          onClick = {() => this.props.onClick(letter)}
          />
      )
    }
  
    renderRow(row) {
      const buttons = row.map((letter) => {
        let type = "guess"
        if (this.props.correct.includes(letter)) {
          type = "correct";
        }
        else if (this.props.order.includes(letter)){
          type = "order";
        }
        else if (this.props.wrong.includes(letter)){
          type = "wrong";
        }
        else if (letter === "Enter")
        {
          type = "enter"
        }
        else if (letter === "<--")
        {
          type = "backspace"
        }
        ;
        return this.renderButton(letter, type);
      })
      return buttons;
    }
  
    render () {
        const rowsRender = this.rows.map((row, i) => {
          const buttons = this.renderRow(row);
          return (
          <div className="KeyboardRow" key={i}>
            {buttons}
          </div>
          );
        });
        return (
          <div className='keyboard'>
            {rowsRender}
          </div>
        );
      }   
  }

export default Keyboard;
var backendUrl = 'https://backend-dot-cz-wordle.lm.r.appspot.com'

export async function getWord() {
    try {
        const response = await fetch(backendUrl+'/word');
        return await response.json();
    } catch(error) {
        return [];
    }
}

export const checkWord = async(letters) => {
    try {
        let word = '';
        letters.forEach(element => {
            word += element;
        });
        const response = await fetch(backendUrl + '/guess/' + word);  
        return await response.json();
    } catch(error) {
        return [];
    }
}